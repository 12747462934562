import classNames from 'classnames'
import React from 'react'
import {RowConfig, TableRowProps, TableRowType} from './interfaces'
import s from './table-row.scss'

export const TableRow = ({config, id, mobile}: TableRowProps) => {
  const {type: style, dataHook, asText, addDivider, className, responsiveHideColumns = []} = config

  return (
    <>
      <div
        id={id}
        className={classNames(s.row, className, {
          [s.small]: style === TableRowType.small,
          [s.large]: style === TableRowType.large,
          [s[`reducedHidesColumn-${responsiveHideColumns.join('-')}`]]: responsiveHideColumns.length,
          [s.desktop]: !mobile,
        })}
        data-hook={dataHook}
        role={asText ? 'text' : 'row'}
      >
        {generateColumns(config)}
      </div>
      {addDivider && (
        <div
          className={classNames(s.dividerRow, {
            [s[`reducedHidesColumn-${responsiveHideColumns.join('-')}`]]: responsiveHideColumns.length,
          })}
        >
          {generateColumns(config, true)}
        </div>
      )}
    </>
  )
}

const generateColumns = (config: RowConfig, divider = false) => {
  let columnRole = config.type === TableRowType.header ? 'columnheader' : 'cell'

  if (config.asText) {
    columnRole = null
  }

  const {dividerClassName: globalDividerClassName} = config

  let columnIndex = 0

  const renderContentElement = (tagName = 'div', contentClassName, dataHook, ariaLabel, content) =>
    React.createElement(
      tagName,
      {
        'data-hook': dataHook,
        className: classNames(s.columnContent, contentClassName),
        'aria-label': ariaLabel,
      },
      content,
    )

  return config.columns.map(column => {
    if (column) {
      const {className, contentClassName, dividerClassName, content, dataHook, ariaLabel, tagName} = column

      return (
        <div
          key={`${config.dataHook}-column-${columnIndex}`}
          className={classNames(s.column, s[`column-${columnIndex++}`], {
            [s.defaultColumn]: !className,
            [className]: className,
          })}
          role={divider ? null : columnRole}
        >
          {divider && (
            <div>
              <div className={classNames(globalDividerClassName || s.divider, dividerClassName)} />
            </div>
          )}
          {!divider && renderContentElement(tagName, contentClassName, dataHook, ariaLabel, content)}
        </div>
      )
    } else {
      return <div key={`${config.itemKey}-column-${columnIndex}`} className={s[`column-${columnIndex++}`]} />
    }
  })
}
